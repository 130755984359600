<template>
  <div>
    <div v-if="forms.length" class="show-file">
      <GroupFiles
        :formsLength="forms.length"
        :isValidated="isValidated"
        @changeGroup="changeGroup"
        @changeGroupName="changeGroupName"
      />
      <div v-for="(form, index) in forms" :key="index">
        <div class="show-file-content">
          <div class="file-content-header">
            <div class="data-file">
              <Pdf v-if="['pdf'].includes(form.type)" class="pdf-icon" />
              <Jpg
                v-else-if="['jpg', 'jpeg', 'jps'].includes(form.type)"
                class="jpg-icon"
              />
              <Png v-else-if="['png'].includes(form.type)" class="png-icon" />
              <div>
                <div class="file-name-wrapper">
                  <div
                    class="file-name"
                    :contenteditable="true"
                    @blur="e => changeName(index, e.target.innerText)"
                  >
                    {{ form.custom_name }}
                  </div>
                </div>
                <span class="edit-label">(Clique no nome para editar)</span>
              </div>
            </div>
            <div class="right-options">
              <Close class="close-icon" @click="removeFile(index)" />
            </div>
          </div>

          <div class="file-type">
            <input
              class="input-file-type"
              type="radio"
              :name="`type_${index}`"
              :id="`intern_${index}`"
              value="intern"
              v-model="form.type_id"
              checked
            />
            <p>Realizado internamente</p>

            <input
              class="input-file-type"
              type="radio"
              :name="`type_${index}`"
              :id="`intern_${index}`"
              value="extern"
              v-model="form.type_id"
            />
            <p>Em outra instituição</p>
          </div>

          <b-progress
            v-if="form.progress"
            class="progress"
            :max="100"
            show-progress
            :animated="form.progress !== 100"
            :variant="!form.error ? 'primary' : 'danger'"
          >
            <b-progress-bar :value="form.progress">
              <span>
                <strong>{{ form.progress }}%</strong>
              </span>
            </b-progress-bar>
          </b-progress>
        </div>
      </div>
      <div class="d-flex justify-content-end p-3">
        <b-button
          v-if="!sent"
          variant="primary"
          :loading="loading"
          :disabled="loading || sent"
          @click="uploadFiles"
        >
          <b-spinner v-if="loading" small variant="light" />
          {{ `Enviar arquivo${forms.length > 1 ? 's' : ''}` }}
        </b-button>
        <b-button
          v-else
          variant="outline-secondary"
          :disabled="loading"
          @click="closeModal"
        >
          Fechar
        </b-button>
      </div>
    </div>

    <div
      v-else
      class="set-file"
      @drop="addFiles"
      @dragover.prevent
      @drop.prevent
    >
      <div class="set-file-content">
        <p class="set-file-content-text">Arraste e solte os arquivos aqui</p>
        <div class="middle-set-file-content">
          <div class="middle-set-file-content-detail" />
          <p class="middle-set-file-content-text">ou</p>
          <div class="middle-set-file-content-detail" />
        </div>
        <label for="set-file-content-input" class="set-file-content-input">
          Anexar arquivos
        </label>
        <input
          multiple
          class="d-none"
          id="set-file-content-input"
          name="set-file-content-input"
          type="file"
          accept=".jpeg, .jpg, .png, .pdf, .jps, .tiff"
          @change="addFiles"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeModal: {
      type: Function,
      required: true
    },
    clinicId: {
      type: String,
      required: true
    },
    patientId: {
      type: String,
      required: true
    },
    surgeryInformationId: {
      type: String,
      required: true
    },
    generalSheetId: String,
  },
  components: {
    Pdf: () => import('@/assets/icons/pdf.svg'),
    Jpg: () => import('@/assets/icons/jpg.svg'),
    Png: () => import('@/assets/icons/png.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    GroupFiles: () => import('@/components/General/GroupFiles')
  },
  data: () => ({
    loading: false,
    sent: false,
    forms: [],
    groupFiles: false,
    isValidated: true,
    groupName: '',
    groupId: null
  }),
  computed: {
    validatedSameTypeFiles() {
      const types = this.forms.map(form => {
        if (form.file.type.includes('image')) {
          return 'image'
        } else {
          return form.file.type
        }
      })
      const uniqueTypes = [...new Set(types)]
      return uniqueTypes.length === 1
    }
  },
  methods: {
    isValidExtension(type, nameExt) {
      return [
        'pdf',
        'jpg',
        'jpeg',
        'png',
        'jps',
        'application/pdf',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'tiff'
      ].includes(type || nameExt)
    },
    addFiles(e) {
      const forms = []
      const files = e.target.files || e.dataTransfer.files
      for (const file of files) {
        const type = file.type.split('/')[1]
        let nameExt = file.name.match(/(?:\.)([0-9a-z]+)$/i)
        nameExt = nameExt.length > 1 ? nameExt[1] : ''
        if (!this.isValidExtension(file.type, nameExt)) {
          return this.$toast.error('Formato de arquivo não suportado!')
        }
        forms.push({
          file,
          type,
          type_id: 'intern',
          custom_name: file.name,
          error: false,
          progress: 0,
          procedure_id: null
        })
      }
      this.forms = forms
    },
    removeFile(index) {
      const forms = [...this.forms]
      forms.splice(index, 1)
      this.forms = forms
    },
    changeGroupName(name) {
      this.groupName = name
    },
    changeGroup(value) {
      this.groupFiles = value
    },
    createFormData(form, customName) {
      const dataForm = new FormData()
      dataForm.append(`file`, form.file)
      dataForm.append(`custom_name`, customName)
      dataForm.append(`type_id`, form.type_id)
      dataForm.append('person_id', this.patientId)
      dataForm.append('clinic_id', this.clinicId)
      dataForm.append('surgery_information_id', this.surgeryInformationId)
      if (this.generalSheetId) {
        dataForm.append('surgery_record_id', this.generalSheetId)
      }
      if (this.groupFiles) {
        dataForm.append('group_name', this.groupName)
        dataForm.append('group', this.groupId)
      }
      return dataForm
    },
    async uploadFiles() {
      if (!this.isValid()) return
      this.loading = true
      for (const form of this.forms) {
        const customName = this.suitName(form.custom_name)
        const dataForm = this.createFormData(form, customName)
        try {
          const { data } = await this.api.createSurgeryInformationFile(
            dataForm,
            {
              onUploadProgress: event => {
                form.progress = Math.round((event.loaded * 100) / event.total)
              }
            }
          )
          this.groupId = data.group

          this.$emit('uploadNewDocument', data)
        } catch (err) {
          form.error = err.message
          this.$toast.warning(`Não foi possível enviar o arquivo ${customName}`)
        }
      }
      this.loading = false
      this.sent = true
      this.$toast.success('Envios finalizados')
    },
    suitName(name) {
      return name
        .replace(/\.[0-9a-z]+$/i, '')
        .replace(/[^\w\sA-Za-zÀ-ÖØ-öø-ÿ-()]/g, '')
    },
    isValid() {
      let valid = true
      if (this.groupFiles && !this.groupName) {
        this.$toast.warning('Informe um nome para o grupo de arquivos')
        this.isValidated = false
        valid = false
      }
      if (this.groupFiles && !this.validatedSameTypeFiles) {
        this.$toast.warning(
          'Não é possível agrupar arquivos de tipos diferentes'
        )
        valid = false
      }
      return valid
    },
    changeName(index, value) {
      const forms = [...this.forms]
      forms[index].custom_name = value
      forms[index].canEdit = false
      this.forms = forms
    }
  },
  watch: {
    sent(value) {
      this.$emit('change-sent', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.show-file {
  .show-file-content {
    display: flex;
    flex-direction: column;
    margin: 16px 24px;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    padding: 10px;

    .file-content-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .data-file {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        .pdf-icon {
          width: 42px !important;
          height: 42px !important;
          margin-left: -5px;
        }

        .jpg-icon {
          width: 42px !important;
          height: 42px !important;
          margin-left: -5px;
        }

        .png-icon {
          width: 42px !important;
          height: 42px !important;
          margin-left: -5px;
        }

        #data-file-text {
          margin-left: 16px;
          width: 40ch;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .file-name-wrapper {
          display: flex;
          align-items: center;
          gap: 8px;
          span {
            color: var(--type-placeholder);
          }
        }
      }

      .close-icon {
        height: 24px;
        width: 24px;
        fill: var(--neutral-500);
      }
    }
  }
}

.progress {
  margin: 5px 24px;
}
.send-button {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}
.right-options {
  display: flex;
  align-items: center;
  gap: 10px;
}
.file-type {
  display: flex;
  align-items: center;
  margin: 8px 0 0 0;

  .input-file-type {
    height: 24px;
    width: 24px;
    margin-right: 12px;
    box-sizing: border-box;
    border-radius: 100px;
    cursor: pointer;
  }

  p + input {
    margin-left: 24px;
  }
}

.set-file {
  margin: 24px;
  .set-file-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    border: 1px dashed var(--blue-500);
    border-radius: 8px;

    .set-file-content-text {
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;
    }

    .middle-set-file-content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: 12px 0;

      .middle-set-file-content-detail {
        width: 54px;
        height: 2px;
        background: var(--neutral-300);
        border-radius: 16px;
      }

      .middle-set-file-content-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        padding: 0 14px;
      }
    }

    .set-file-content-input {
      width: auto;
      height: auto;
      padding: 8px 16px;
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;
      color: var(--neutral-000);
      border-radius: 8px;
      background: var(--blue-700);
      cursor: pointer;
    }
  }
}
.right-options {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
